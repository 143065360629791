// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-login-js": () => import("./../../../src/pages/admin/login.js" /* webpackChunkName: "component---src-pages-admin-login-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-terminbuchung-appointment-contact-form-js": () => import("./../../../src/pages/terminbuchung/AppointmentContactForm.js" /* webpackChunkName: "component---src-pages-terminbuchung-appointment-contact-form-js" */),
  "component---src-pages-terminbuchung-appointment-date-picker-js": () => import("./../../../src/pages/terminbuchung/AppointmentDatePicker.js" /* webpackChunkName: "component---src-pages-terminbuchung-appointment-date-picker-js" */),
  "component---src-pages-terminbuchung-appointment-details-js": () => import("./../../../src/pages/terminbuchung/AppointmentDetails.js" /* webpackChunkName: "component---src-pages-terminbuchung-appointment-details-js" */),
  "component---src-pages-terminbuchung-appointment-flow-js": () => import("./../../../src/pages/terminbuchung/AppointmentFlow.js" /* webpackChunkName: "component---src-pages-terminbuchung-appointment-flow-js" */),
  "component---src-pages-terminbuchung-appointment-review-js": () => import("./../../../src/pages/terminbuchung/AppointmentReview.js" /* webpackChunkName: "component---src-pages-terminbuchung-appointment-review-js" */),
  "component---src-pages-terminbuchung-appointment-slot-picker-js": () => import("./../../../src/pages/terminbuchung/AppointmentSlotPicker.js" /* webpackChunkName: "component---src-pages-terminbuchung-appointment-slot-picker-js" */),
  "component---src-pages-terminbuchung-index-js": () => import("./../../../src/pages/terminbuchung/index.js" /* webpackChunkName: "component---src-pages-terminbuchung-index-js" */)
}

