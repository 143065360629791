import { createTheme } from "@mui/material";

const sibylleTheme = createTheme({
    typography: {
        fontFamily: 'ff-real-headline-pro, sans-serif',
        h1: {
            fontSize: 21,
            fontWeight: 400,
        },
        h2: {
            fontSize: 21,
            fontWeight: 400,
            marginTop: 10,
            paddingBottom: 10
        },
        h3: {
            fontSize: 17,
            fontWeight: 400,
        },
        subtitle1: {
            fontSize: 15,
            fontWeight: 300,
        },
        body1: {
          fontSize: 17,
          fontWeight: 300,
        },
        body2: {
          fontSize: 15,
          fontWeight: 300,
        },
        button: {
          textTransform: 'none',
          fontSize: 17,
          fontWeight: 200
        },
      },
  palette: {
    primary: { 500: '#ff6019' },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          boxShadow: 4,
          borderRadius: 50,
          height: 56,
          paddingLeft: 30,
          paddingRight: 30,
          marginTop: 10
        }
      }
    }, 
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 12
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 25,
          paddingTop: 25
        }
      }
    }
  }
})

export default sibylleTheme